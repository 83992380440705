<template>
    <v-app id="inspire">
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="5">
                    <v-card class="elevation-2" v-if="!user">
                        <v-toolbar color="primary" dark flat>
                            <v-toolbar-title class="subtitle-1">
                                Sign in with <strong class="">Buzzooka CRM</strong>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form>
                                <v-text-field
                                        label="Email"
                                        v-model="email"
                                        prepend-icon="mdi-account"
                                        type="text"
                                />
                                <v-text-field
                                        id="password"
                                        label="Password"
                                        v-model="password"
                                        prepend-icon="mdi-lock"
                                        type="password"
                                />
                            </v-form>
                        </v-card-text>

                        <v-card-actions v-if="errors.length === 0">
                            <v-btn text class="text-none px-5" @click="create_account">Not a member yet?</v-btn>
                            <v-spacer />
                            <v-btn dark class="text-none px-5" color="primary" @click="social_login">Sign In</v-btn>
                        </v-card-actions>
                        <v-card-text v-else>
                            <v-banner single-line style="border: 1px solid grey;">
                                <v-icon slot="icon" color="error" size="36">
                                    mdi-alert
                                </v-icon>
                                <div v-for="error in errors" class="block" :key="error">{{ error }}</div>
                            </v-banner>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import request from '@/services/axios_instance'
    import {mapGetters} from "vuex";
    import store from '@/store/store'
    import Vue from "vue";

    export default {
        name: "SignInWith",
        data: () => ({
            redirect_uri: null,
            email: null,
            password: null,
            params: []
        }),
        created() {
            this.params = this.$route.query
            if (this.user && this.params.redirect_uri) {
                if(this.$route.params.source === 'canva') {
                    this.create_canva_client_for_user(() => {
                        window.location = `${this.params.redirect_uri}?state=${this.params.state}&success=true`
                    })
                } else {
                    window.location = `${this.params.redirect_uri}?state=${this.params.state}&success=true`
                }
            }
        },
        computed: {
            ...mapGetters(['user']),
            errors() {
                let error = []
                if (!this.params.redirect_uri) error.push('Missing parameter: redirect_uri')
                if (!this.params.user) error.push('Missing parameter: user ')
                if (!this.params.state) error.push('Missing parameter: state ')
                return error
            }
        },
        methods: {
            create_canva_client_for_user(cb) {
                let payload = {
                    source: 'canva',
                    user: this.$route.query.user
                }
                request.post(`api/canva/create-client`, payload)
                    .then(({data}) => {
                        if (typeof  cb === 'function') {
                            cb(data)
                        }
                    })
            },
            create_account() {
                window.open(`${this.settings.BaseURL}/signup`, '_blank')
            },
            social_login() {
                let payload = {
                    email: this.email,
                    password: this.password,
                    source: this.$route.params.source,
                    user: this.$route.query.user || null
                }
                request.post(`api/sign-in-with`, payload)
                    .then(({data}) => {
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('user', Vue.CryptoJS.AES.encrypt(JSON.stringify(data.user), this.settings.paraphrase).toString())
                        store.dispatch('login', data)
                            .then(() => {
                                window.location = `${this.params.redirect_uri}?state=${this.params.state}&success=${data.success}`
                            })
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    #inspire {
        background-image: linear-gradient(#cdedff 40%, #f7f9fb 70%);
    }
</style>